<template>
  <div class="form-elements">
    <div class="row">
      <div class="flex xs12">
        <vac-card :title="$t(`Edit user ${$route.params.id}`)">
          <form @submit.prevent="onSubmit">

            <div class="row">
              <div class="flex md6 sm6 xs12">
                <va-input
                  v-model="username"
                  placeholder="Login"
                  class="input--bg"
                />
              </div>
              <div class="flex md6 sm6 xs12">
                <va-input
                  v-model="firstname"
                  placeholder="Name"
                  class="input--bg"
                />
              </div>
            </div>

            <div class="row">
              <div class="flex md6 sm6 xs12">
                <va-input
                  v-model="lastname"
                  placeholder="Last name"
                  class="input--bg"
                />
              </div>
              <div class="flex md6 sm6 xs12">
                <va-input
                  v-model="email"
                  placeholder="E-mail"
                  class="input--bg"
                />
              </div>
            </div>
            <va-button class="btn--wide"
              type="submit"
              :style="{marginTop: '64px'}">
              Save
            </va-button>
          </form>
          <va-progress-bar v-if="loading"
            indeterminate
            :style="{width: '100%', position: 'absolute', bottom: 0, left: 0, backgroundColor: '#ffffff'}"></va-progress-bar>
        </vac-card>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';
  import VaButton from 'vuestic-ui/src/components/vuestic-components/va-button/VaButton';
  import HelperUser from "../../services/Helpers/HelperUser";
  import { sortArrayOfObject } from "../../services/Helpers/HelperObject";
  import { showToastError, showToastSuccess } from "../../services/Helpers/HelperToast";
  export default {
    name: 'edit',
    components: { VaButton },
    created() {
      axios.get(`${process.env.VUE_APP_API_URL}/users/${this.$route.params.id}`, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
        },
      }).then(response => {
        const user = response.data.data;
        this.id = user.id;
        this.username = user.username;
        this.firstname = user.firstName;
        this.lastname = user.lastName;
        this.email = user.email;

      }).catch(error => console.error(error.message));
    },
    data() {
      return {
        id: null,
        username: '',
        firstname: '',
        lastname: '',
        email: '',
        password: '',
        tabValue: 0,
        retailers: [],
        loading: false,
      };
    },
    methods: {

      collectColumns(data) {
        const length = Math.ceil(data.length / 3);
        let output = [];
        for (let index = 0; index < 3; index++) {
          const multiplier = index * length;
          const innerArray = data.slice(multiplier, multiplier + length);
          output.push(innerArray);
        }
        return output;
      },

      onSubmit() {
        this.loading = true;

        const data = {
          user: {
            id: this.id,
            username: this.username,
            firstname: this.firstname,
            lastname: this.lastname,
            email: this.email,
          },
        };

        let saved = this.$store.dispatch('saveUser', data);

        saved.then(() => {
          this.loading = false;
          showToastSuccess("User saved success", this.$toast);
        }).catch(error => {
          this.loading = false;
          showToastError(error, this.$toast);
        });
      },
    },
  };
</script>

<style lang="scss">
  .row.row-inside {
    max-width: none;
  }
</style>
